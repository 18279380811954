// Walls.jsx
import React from 'react';
import { WALLS } from './constants';

const Walls = () => {
  return (
    <>
      {WALLS.map((wall) => (
        <div
          key={wall.id} // Ensure each wall has a unique key
          className="wall"
          style={{
            top: `${wall.y}px`,
            left: `${wall.x}px`,
            width: `${wall.width}px`,
            height: `${wall.height}px`,
            position: 'absolute',
            backgroundColor: 'green', // Adjust as needed
          }}
        />
      ))}
    </>
  );
};

export default Walls;
