// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bullet-indicator {
    display: flex;
    gap: 5px; /* Adjust spacing between bullets */
    position: absolute;
    top: 10px; /* Adjust the position as needed */
    right: 10px; /* Adjust the position as needed */
  }
  
  .bullet {
    width: 15px; /* Adjust the size of the bullet indicator */
    height: 15px; /* Adjust the size of the bullet indicator */
    border-radius: 50%; /* Make the bullets round */
    border: 2px solid black; /* Outline for the bullets */
  }
  
  .bullet.filled {
    background-color: #b0b0b0; /* Color for filled bullets */
  }
  
  .bullet.empty {
    background-color: transparent; /* Color for empty bullets */
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/BulletIndicator.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,QAAQ,EAAE,mCAAmC;IAC7C,kBAAkB;IAClB,SAAS,EAAE,kCAAkC;IAC7C,WAAW,EAAE,kCAAkC;EACjD;;EAEA;IACE,WAAW,EAAE,4CAA4C;IACzD,YAAY,EAAE,4CAA4C;IAC1D,kBAAkB,EAAE,2BAA2B;IAC/C,uBAAuB,EAAE,4BAA4B;EACvD;;EAEA;IACE,yBAAyB,EAAE,6BAA6B;EAC1D;;EAEA;IACE,6BAA6B,EAAE,4BAA4B;EAC7D","sourcesContent":[".bullet-indicator {\n    display: flex;\n    gap: 5px; /* Adjust spacing between bullets */\n    position: absolute;\n    top: 10px; /* Adjust the position as needed */\n    right: 10px; /* Adjust the position as needed */\n  }\n  \n  .bullet {\n    width: 15px; /* Adjust the size of the bullet indicator */\n    height: 15px; /* Adjust the size of the bullet indicator */\n    border-radius: 50%; /* Make the bullets round */\n    border: 2px solid black; /* Outline for the bullets */\n  }\n  \n  .bullet.filled {\n    background-color: #b0b0b0; /* Color for filled bullets */\n  }\n  \n  .bullet.empty {\n    background-color: transparent; /* Color for empty bullets */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
