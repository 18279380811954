// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.enemy {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #c0c0c0; /* Adjust the color to match the tumbleweed */
  border-radius: 50%; /* Makes the enemy appear as a circle */
  caret-color: transparent; /* Hides the cursor */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: white;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8); /* Adds a subtle shadow to text */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Adds a shadow around the enemy */
  z-index: 1; /* Ensures the enemy appears above other elements */
}

/* Use an emoji or Unicode character for the angry tumbleweed */
.enemy::before {
  content: '🌀'; /* Unicode character for a whirlwind, adjust as needed */
  font-size: 24px; /* Adjust size as needed */
}`, "",{"version":3,"sources":["webpack://./src/Components/Enemy.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,yBAAyB,EAAE,6CAA6C;EACxE,kBAAkB,EAAE,uCAAuC;EAC3D,wBAAwB,EAAE,qBAAqB;EAC/C,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,iBAAiB;EACjB,YAAY;EACZ,2CAA2C,EAAE,iCAAiC;EAC9E,uCAAuC,EAAE,mCAAmC;EAC5E,UAAU,EAAE,mDAAmD;AACjE;;AAEA,+DAA+D;AAC/D;EACE,aAAa,EAAE,wDAAwD;EACvE,eAAe,EAAE,0BAA0B;AAC7C","sourcesContent":[".enemy {\r\n  position: absolute;\r\n  width: 30px;\r\n  height: 30px;\r\n  background-color: #c0c0c0; /* Adjust the color to match the tumbleweed */\r\n  border-radius: 50%; /* Makes the enemy appear as a circle */\r\n  caret-color: transparent; /* Hides the cursor */\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  font-size: 16px;\r\n  font-weight: bold;\r\n  color: white;\r\n  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8); /* Adds a subtle shadow to text */\r\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Adds a shadow around the enemy */\r\n  z-index: 1; /* Ensures the enemy appears above other elements */\r\n}\r\n\r\n/* Use an emoji or Unicode character for the angry tumbleweed */\r\n.enemy::before {\r\n  content: '🌀'; /* Unicode character for a whirlwind, adjust as needed */\r\n  font-size: 24px; /* Adjust size as needed */\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
