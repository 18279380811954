// Projectile.js
import React from 'react';

const Projectile = ({ projectiles }) => (
  projectiles.map((projectile, index) => (
    <div
      key={index}
      className="projectile"
      style={{
        top: `${projectile.y}px`,
        left: `${projectile.x}px`,
      }}
    />
  ))
);

export default Projectile;
