
import { SPEED, MAZE_HEIGHT,MAZE_WIDTH,PLAYER_HEIGHT,PLAYER_WIDTH } from './constants';

//Return true if player is within bounds
const checkBoundY = (y) => {
    return (
        y >= 0 &&
        y <= MAZE_HEIGHT - PLAYER_HEIGHT
    );
}

const checkBoundX = (x) => {

    return (
        x >= 0 &&
        x <= MAZE_WIDTH - PLAYER_WIDTH
    );
}

export const checkWallCollision = (x, y, wall) => {
    return (
        x < wall.x + wall.width &&
        x + PLAYER_WIDTH > wall.x &&
        y < wall.y + wall.height &&
        y + PLAYER_HEIGHT > wall.y 
    );
};


export const movePlayer = (playerPosition, movements) => {

    let newX = playerPosition.x;
    let newY = playerPosition.y;
    

    if(movements.movingRight) {
      newX += SPEED;
    }
    if(movements.movingLeft) {
      newX -= SPEED;
    }
    if(movements.movingUp) {
      newY -= SPEED;
    }
    if(movements.movingDown) {
      newY += SPEED;
    }
    
    if(!checkBoundX(newX)) {
      newX = playerPosition.x;
    }
    if(!checkBoundY(newY)) {
      newY = playerPosition.y;
    }

    return { x: newX, y: newY };
};

