import React from 'react';
import './BulletIndicator.css';

const BulletIndicator = ({ bullets, maxBullets }) => {
  return (
    <div className="bullet-indicator">
      {[...Array(maxBullets)].map((_, index) => (
        <div key={index} className={`bullet ${index < bullets ? 'filled' : 'empty'}`}></div>
      ))}
    </div>
  );
};

export default BulletIndicator;
