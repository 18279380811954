import './App.css';
import React, { useState, useEffect } from 'react';
import Maze from './Components/Maze';


function App() {

  const [highscore, setHighscore] = useState(0);


  useEffect(() => {

  
  
  });

  return (
    <div className="App">
      <Maze/>
    </div>
  );
}

export default App;
