// constants.js
export const WALLS = [

 
];
export const SPEED = 5;
export const MAZE_WIDTH = 800;
export const MAZE_HEIGHT = 600;
export const SHOOT_DELAY = 300;
export const SHOOT_SPEED = 10;
export const PROJECTILE_ALIVE_TIME = 10000000;
export const ENEMY_SPEED = 2;
export const PLAYER_WIDTH = 32;
export const PLAYER_HEIGHT = 32;
export const MAX_BULLETS = 5;
export const IP_ADRESS_SERVER = 'http://129.151.193.155'
export const MIN_SPAWN_DISTANCE = 100;