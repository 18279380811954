// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.highscores {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

table {
    border-collapse: collapse;
    margin: 20px 0;
    width: 80%; /* Adjust this as needed */
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
}

th {
    background-color: #f2f2f2;
    color: black;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Highscores.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,UAAU,EAAE,0BAA0B;AAC1C;;AAEA;IACI,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB","sourcesContent":[".highscores {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    text-align: center;\n}\n\ntable {\n    border-collapse: collapse;\n    margin: 20px 0;\n    width: 80%; /* Adjust this as needed */\n}\n\nth, td {\n    border: 1px solid #ddd;\n    padding: 8px;\n}\n\nth {\n    background-color: #f2f2f2;\n    color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
