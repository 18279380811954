// Player.js
import React from 'react';

const Player = ({ position, movingRight, movingLeft, movingUp, movingDown, idle_direction }) => {
  let playerDirectionClass = '';

  if (movingRight) {
    playerDirectionClass = 'moveRight';
  } else if (movingLeft) {
    playerDirectionClass = 'moveLeft';
  } else if (movingUp) {
    playerDirectionClass = 'moveUp';
  } else if (movingDown) {
    playerDirectionClass = 'moveDown';
  } else {
    // If none of the movement keys are pressed, use idle direction
    switch (idle_direction) {
      case 'left':
        playerDirectionClass = 'idleLeft';
        break;
      case 'right':
        playerDirectionClass = 'idleRight';
        break;
      case 'up':
        playerDirectionClass = 'idleUp';
        break;
      case 'down':
        playerDirectionClass = 'idleDown';
        break;
      default:
        playerDirectionClass = 'idleDown'; // Default to idle down if no specific idle direction is provided
        break;
    }
  }

  return (
    <div className="player-container">
      <div
        className={`player ${playerDirectionClass}`}
        style={{
          top: `${position.y}px`,
          left: `${position.x}px`,
        }}
      />
    </div>
  );
};

export default Player;
