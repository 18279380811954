// Enemy.js
import React from 'react';

const Enemy = ({ enemies }) => (
  enemies.map((enemy) => (
    <div
      key={enemy.id}
      className="enemy"
      style={{
        top: `${enemy.y}px`,
        left: `${enemy.x}px`,
      }}
    />
  ))
);

export default Enemy;
