// useKeyPress.js
import { useEffect } from 'react';

const useKeyPress = (handleKeyPress, handleKeyRelease) => {
  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    window.addEventListener('keyup', handleKeyRelease);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
      window.removeEventListener('keyup', handleKeyRelease);
    };
  }, [handleKeyPress, handleKeyRelease]);
};

export default useKeyPress;
