// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.projectile {
  position: absolute;
  width: 10px; /* Adjust width of projectile */
  height: 10px; /* Adjust height of projectile to make it more bullet-like */
  background-color: #b0b0b0; /* Metallic color for the bullet */  border-radius: 5px; /* Rounded corners to resemble a bullet shape */
  caret-color: transparent;
  border: 2px solid black; /* Optional: Add a border for a more distinct look */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* Optional: Add a shadow for depth */
}
`, "",{"version":3,"sources":["webpack://./src/Components/Projectile.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW,EAAE,+BAA+B;EAC5C,YAAY,EAAE,4DAA4D;EAC1E,yBAAyB,EAAE,kCAAkC,GAAG,kBAAkB,EAAE,+CAA+C;EACnI,wBAAwB;EACxB,uBAAuB,EAAE,oDAAoD;EAC7E,sCAAsC,EAAE,qCAAqC;AAC/E","sourcesContent":[".projectile {\r\n  position: absolute;\r\n  width: 10px; /* Adjust width of projectile */\r\n  height: 10px; /* Adjust height of projectile to make it more bullet-like */\r\n  background-color: #b0b0b0; /* Metallic color for the bullet */  border-radius: 5px; /* Rounded corners to resemble a bullet shape */\r\n  caret-color: transparent;\r\n  border: 2px solid black; /* Optional: Add a border for a more distinct look */\r\n  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* Optional: Add a shadow for depth */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
