import React, { useState, useEffect } from 'react';
import './Maze.css';
import './Projectile.css';
import './Enemy.css';
import Player from './Player.jsx';
import Enemy from './Enemy.jsx';
import Projectile from './Projectile.jsx';
import BulletIndicator from './BulletIndicator.jsx';
import { WALL, SPEED, MAZE_WIDTH, MAZE_HEIGHT, SHOOT_DELAY, SHOOT_SPEED, PROJECTILE_ALIVE_TIME, ENEMY_SPEED,
  PLAYER_WIDTH, PLAYER_HEIGHT, MAX_BULLETS, MIN_SPAWN_DISTANCE, IP_ADRESS_SERVER } from './constants.jsx';
import useKeyPress from './useKeyPress.jsx';
import { checkWallCollision, movePlayer } from './Collision.jsx';
import backgroundSoundtrack from '../music/gamesoundtrack.mp3';
import Walls from './Walls.jsx';
import Highscores from './Highscores.jsx';

const Maze = () => {
  const [playerPosition, setPlayerPosition] = useState({ x: 500, y: 250 });
  const [movingRight, setMovingRight] = useState(false);
  const [movingUp, setMovingUp] = useState(false);
  const [movingDown, setMovingDown] = useState(false);
  const [movingLeft, setMovingLeft] = useState(false);
  const [shooting, setShooting] = useState(false);
  const [projectiles, setProjectiles] = useState([]);
  const [direction, setDirection] = useState('right');
  const [canShoot, setCanShoot] = useState(true);
  const [enemies, setEnemies] = useState([]);
  const [score, setScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [enemySpawnRate, setEnemySpawnRate] = useState(5000);
  const [bullets, setBullets] = useState(3);
  const [audio] = useState(new Audio(backgroundSoundtrack));
  const [userScores, setUserScores] = useState([]);

  const loadScoresFromLocalStorage = () => {
    const scores = JSON.parse(localStorage.getItem('userScores')) || [];
    setUserScores(scores);
  };

  const saveScoresToLocalStorage = (scores) => {
    localStorage.setItem('userScores', JSON.stringify(scores));
  };

  const submitScore = async () => {
    const name = prompt('Enter your name: ');
    const newScore = { name, score: parseInt(score, 10) };

    // Save the score locally
    const updatedScores = [...userScores, newScore];
    setUserScores(updatedScores);
    saveScoresToLocalStorage(updatedScores);

  };

  useEffect(() => {
    loadScoresFromLocalStorage();
  }, []);

  useEffect(() => {
    const initializeAudio = () => {
      audio.play().then(() => {
        audio.pause(); // Initialize the audio
        window.removeEventListener('click', initializeAudio);
        window.removeEventListener('keydown', initializeAudio);
      }).catch((error) => {
        console.error('Failed to initialize audio:', error);
      });
    };

    window.addEventListener('click', initializeAudio);
    window.addEventListener('keydown', initializeAudio);

    return () => {
      window.removeEventListener('click', initializeAudio);
      window.removeEventListener('keydown', initializeAudio);
    };
  }, []);

  useEffect(() => {
    if (score % 10 === 0 && score !== 0) {
      setBullets((prevBullets) => Math.min(prevBullets + 1, MAX_BULLETS));
    }
  }, [score]);

  const handleKeyPress = (event) => {
    if (gameOver) return;

    if (audio.paused) {
      audio.play().catch((error) => {
        console.error('Failed to play audio:', error);
      });
    }
    switch (event.key) {
      case 'ArrowLeft':
      case 'a':
      case 'A':
        setMovingLeft(true);
        setDirection('left');
        break;
      case 'ArrowRight':
      case 'd':
      case 'D':
        setMovingRight(true);
        setDirection('right');
        break;
      case 'ArrowUp':
      case 'w':
      case 'W':
        setMovingUp(true);
        setDirection('up');
        break;
      case 'ArrowDown':
      case 's':
      case 'S':
        setMovingDown(true);
        setDirection('down');
        break;
      case ' ':
        if (!shooting) {
          setShooting(true);
        }
        break;
      default:
        break;
    }
  };

  const handleKeyRelease = (event) => {

    switch (event.key) {
      case 'ArrowLeft':
      case 'a':
      case 'A':
        setMovingLeft(false);
        break;
      case 'ArrowRight':
      case 'd':
      case 'D':
        setMovingRight(false);
        break;
      case 'ArrowUp':
      case 'w':
      case 'W':
        setMovingUp(false);
        break;
      case 'ArrowDown':
      case 's':
      case 'S':
        setMovingDown(false);
        break;
      case ' ':
        setShooting(false);
        break;
      default:
        break;
    }
  };

  const isCollision = (rect1, rect2) => {
    return (
      rect1.x < rect2.x + rect2.width &&
      rect1.x + rect1.width > rect2.x &&
      rect1.y < rect2.y + rect2.height &&
      rect1.y + rect1.height > rect2.y
    );
  };

  const getDistance = (x1, y1, x2, y2) => {
    return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
  };

  useKeyPress(handleKeyPress, handleKeyRelease);

  useEffect(() => {
    if (gameOver) {
      audio.pause();
      //freeze input of movement
    }
  }, [gameOver]);

  useEffect(() => {
    const enemySpawnInterval = setInterval(() => {
      let randomX, randomY;

      do {
        randomX = Math.floor(Math.random() * MAZE_WIDTH);
        randomY = Math.floor(Math.random() * MAZE_HEIGHT);
      } while (getDistance(randomX, randomY, playerPosition.x, playerPosition.y) < MIN_SPAWN_DISTANCE);

      setEnemies((prevEnemies) => [
        ...prevEnemies,
        { id: Date.now(), x: randomX, y: randomY, width: 32, height: 32, speed: ENEMY_SPEED }
      ]);
    }, enemySpawnRate, playerPosition);

    return () => clearInterval(enemySpawnInterval);
  }, [enemySpawnRate]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (movingRight || movingLeft || movingUp || movingDown) {
        let newPos = movePlayer(playerPosition, { movingRight, movingLeft, movingUp, movingDown });
        setPlayerPosition(newPos);
      }

      setEnemies((prevEnemies) => {
        return prevEnemies.map((enemy) => {
          if (isCollision(enemy, { ...playerPosition, width: PLAYER_WIDTH, height: PLAYER_HEIGHT })) {
            console.log('Monster collision with player detected!');
            setGameOver(true);
            setEnemies([]);
            setProjectiles([]);
            setEnemySpawnRate(50000000);
          }
          let deltaX = playerPosition.x - enemy.x;
          let deltaY = playerPosition.y - enemy.y;
          let angle = Math.atan2(deltaY, deltaX);
          let newX = enemy.x + Math.cos(angle) * enemy.speed;
          let newY = enemy.y + Math.sin(angle) * enemy.speed;
          return { ...enemy, x: newX, y: newY };
        });
      });

      setProjectiles((prevProjectiles) => {
        return prevProjectiles.map((projectile) => {
          let newProjectile = { ...projectile };

          if (
            newProjectile.x <= 0 ||
            newProjectile.x + 15 >= MAZE_WIDTH ||
            newProjectile.y - 5 <= 0 ||
            newProjectile.y + 15 >= MAZE_HEIGHT
          ) {
            switch (newProjectile.dir) {
              case 'right':
                newProjectile.dir = 'left';
                break;
              case 'left':
                newProjectile.dir = 'right';
                break;
              case 'up':
                newProjectile.dir = 'down';
                break;
              case 'down':
                newProjectile.dir = 'up';
                break;
              default:
                break;
            }
          }

          switch (newProjectile.dir) {
            case 'right':
              newProjectile.x += SHOOT_SPEED;
              break;
            case 'left':
              newProjectile.x -= SHOOT_SPEED;
              break;
            case 'up':
              newProjectile.y -= SHOOT_SPEED;
              break;
            case 'down':
              newProjectile.y += SHOOT_SPEED;
              break;
            default:
              break;
          }

          if (isCollision(newProjectile, { ...playerPosition, width: PLAYER_WIDTH, height: PLAYER_HEIGHT })) {
            console.log('Projectile collision with player detected!');
            setGameOver(true);
            setEnemies([]);
            setProjectiles([]);
            setEnemySpawnRate(50000000);
          }

          enemies.forEach((enemy) => {
            if (isCollision(newProjectile, enemy)) {
              console.log('Projectile collision with enemy detected!');
              setEnemySpawnRate((prevRate) => prevRate - 100); // Increase enemy spawn rate
              setScore((prevScore) => prevScore + 1);
              setEnemies((prevEnemies) => prevEnemies.filter((e) => e.id !== enemy.id));
            }
          });

          return newProjectile;
        });
      });

      if (shooting && canShoot && bullets > 0) {
        const projectileId = Date.now();
        let initialX = playerPosition.x;
        let initialY = playerPosition.y;

        switch (direction) {
          case 'right':
            initialX += 30;
            initialY += 20;
            break;
          case 'left':
            initialX -= 10;
            initialY += 20;
            break;
          case 'up':
            initialX += 20;
            initialY -= 10;
            break;
          case 'down':
            initialY += 30;
            initialX += 20;
            break;
          default:
            break;
        }

        setProjectiles((prevProjectiles) => [
          ...prevProjectiles,
          { id: projectileId, x: initialX, y: initialY, dir: direction, width: 10, height: 10 },
        ]);
        setCanShoot(false);
        setBullets((prevBullets) => Math.max(prevBullets - 1, 0));
        setTimeout(() => {
          setCanShoot(true);
        }, SHOOT_DELAY);
        setTimeout(() => {
          setProjectiles((prevProjectiles) => prevProjectiles.filter((projectile) => projectile.id !== projectileId));
        }, PROJECTILE_ALIVE_TIME);
      }
    }, 1000 / 60);

    return () => clearInterval(interval);
  }, [movingRight, movingLeft, movingUp, movingDown, playerPosition, shooting, canShoot, enemies]);

  const handlePlayAgain = () => {
    setPlayerPosition({ x: 500, y: 250 });
    setMovingRight(false);
    setMovingLeft(false);
    setMovingUp(false);
    setMovingDown(false);
    setShooting(false);
    setProjectiles([]);
    setDirection('right');
    setCanShoot(true);
    setEnemies([]);
    setScore(0);
    setGameOver(false);
    setEnemySpawnRate(5000);
    setBullets(3);
    loadScoresFromLocalStorage();
  };

  return (
    <div className="maze" style={{ top: `${(window.innerHeight / 2) - 400}px`, left: `${(window.innerWidth / 2) - 400}px` }}>
      <Walls />
      <Player position={playerPosition} movingRight={movingRight} movingLeft={movingLeft} movingUp={movingUp} movingDown={movingDown} idle_direction={direction} />
      <Enemy enemies={enemies} />
      <Projectile projectiles={projectiles} />
      <BulletIndicator bullets={bullets} maxBullets={MAX_BULLETS} />
      <div className="score">Score: {score}</div>

      {gameOver && (
        <div className="modal-overlay">
          <div className="game-over-modal">
            <h2>Game Over</h2>
            <p>You died!</p>
            <Highscores scores={userScores}/>
            <p>Your score: {score}</p>
            <div className="modal-buttons">
              <button className="play-again-button" onClick={handlePlayAgain}>Play Again</button>
              <button className="submit-score-button" onClick={submitScore}>Save Score</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Maze;
